.partnerIconWrapper {
    display: inline-flex;
    width: fit-content;
    padding-right: 5px;
    padding-left: 0px;
}

.addOrgTab:hover {
    background-color: rgb(49, 116, 148);
    color: white !important;
}

.addOrgTab {
    color: black;
}

.inactiveOrgTab:hover {
    background-color: rgb(41, 153, 205);
    color: white !important;
}

.partnerLink {
    color: #2999cd;
    text-decoration: underline;
    text-decoration-color: #2999cd;
}

.partnerLink:hover {
    color: #217aa4;
    text-decoration: underline;
    text-decoration-color: #217aa4;
    cursor: pointer;
}

.serviceColItem {
    padding-bottom: 0.5rem;
}

/* select last 1 child */
.serviceColItem:nth-last-child(-n + 1) {
    padding-bottom: 0;
}

.convoMsgCard:nth-last-child(-n + 1) {
    padding-bottom: 0;
}

.convoContainer {
    height: calc(100vh - var(--navbar-height) - 1.5rem - 48px - 1.5rem);
}

.badge {
    height: 25px;
    width: 25px;
    color: var(--badge-color);
    border: 3px solid var(--badge-color);
    border-radius: 50% 50% 50% 50% / 12% 12% 88% 88%;
}