.orgSelect {
    min-width: #{"min(150px, 20%)"};
}

.navItem {
    height: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: padding 100ms ease-in-out, border-radius 300ms ease-in-out;
    border-radius: 20px;
    border: 1px solid var(--bs-gray-300);

    div {
        transition: max-width 100ms ease;
        overflow: hidden;
        max-width: 20px;
        svg {
            width: 20px;
            height: 20px;
        }
    }
    &:hover, &:global(.active) {
        div {
            max-width: 200px;
        }
        border-radius: 20rem !important;
    }
    &:global(.active) {
        border-color: var(--primary);
    }
    &:not(:hover):not(:global(.active)) {
        height: 40px;
        padding-left: 9px !important;
        padding-right: 9px !important;
    }
}