.itemBody {
    transition: all .3s ease;
    cursor: pointer;
}

.itemBody p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 25rem;
}

.itemBody .dropdown {
    opacity: 0;
    transition: all .3s ease;
}

.itemBody:hover {
    background: var(--bs-gray-300);
}

.itemBody:hover .dropdown {
    opacity: 1;
}