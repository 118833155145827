button.toggle-btn {
    height: fit-content;

    &:not(.anim) {
        transition: all .5s ease, border-radius 0s;

        &:not(.active) {
            border-radius: 50%;
        }
        &.active {
            border-radius: 20rem;
        }
    }
}


.emoji-picker {
    & > :global(.emoji-mart) {
        border: none;
    }
    :global(.emoji-mart-search-icon) {
        top: 3px;
    }
    button:global(.emoji-mart-emoji) span {
        cursor: pointer;
    }
}

/* Placeholder Image */
.list .placeholder {
    width: min-content;
    height: 25px;
    font-size: 5px;
}

.grid .placeholder {
    font-size: x-small;
    width: min-content;
    height: 55px;
    margin-left: auto;
    margin-right: auto;
}
.placeholder {
    position: relative;
}
.placeholder > img.placeholderImage {
    width: auto;
    height: 100%;
    filter: drop-shadow(0 1px 1px var(--bs-gray-300));
}
.placeholder > div {
    text-align: center;
    color: var(--white);
    font-weight: bold;
    border-radius: 1px;
    box-shadow: 0 1px 1px var(--primary-dark);
    background: var(--primary);
    position: absolute;
    width: calc(100% + 2px);
    left: -1px;
    bottom: 20%;
}

.searchContainer {
    position: relative;
    width: min-content;
}

.searchContainer > input {
    /* padding to offset the search button */
    padding-left: 0.85rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: calc(0.5rem + 17px);
    background-color: var(--white);
    border-radius: 12px;
}

.searchContainer button {
    position: absolute !important;
    right: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
}