button.signUpBtn {
    z-index: 1;
    padding: 0.5rem 3rem;
    font-size: 1.25rem;
    border-radius: 20rem;
    width: max-content;
    position: 'absolute';
    top:0;
    right:0;
}
