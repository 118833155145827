// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'bootstrap/scss/functions';

// 2. Include any default variable overrides here
/********
 * Customize
 ********
 */
/* Color */
/* brand colours */
// neutral
$black: #222222;
$grey: #a1a8b2;
$grey-light: #d3d3d3;
$grey-light2: #f1f1f1;
$grey-lighter: #f9f9f9;
// primary

$primary: #AA8066;
$primary-dark: #94664a;
$primary-light: #c89e84;
$primary-lighter: #f5d6c4;
// secondary
$secondary-red: #dd301d;
$secondary-green: #5dbb72;
$secondary-purple: #7500b0;
$secondary-yellow: #fcd11c;

/* bootstrap colours */
$body-bg: #e5e5e5;
$secondary: $grey;
$success: $secondary-green;
$text-muted: $grey;

/* custom colours */
$bg-secondary: rgb(247, 249, 249);
$nav-link-item: #8a8a8a;
$white: #ffffff;

/********
 * Utilities
 ********
 */
/* Borders */
$border-radius-sm: 4px;
$border-radius: 6px;
$border-radius-lg: 8px;

/* Text */
$font-size-base: 0.9rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.9;
$small-font-size: $font-size-sm;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.4;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1.1;

$mark-bg: $primary-lighter;
//TODO
//https://getbootstrap.com/docs/5.0/utilities/text/#sass

/********
 * Forms
 ********
 */
/* form control */
$input-bg: $white;

/********
 * Components
 ********
 */
/* buttons */
// $btn-padding-y: 0.3rem;
// $btn-padding-x: 1.4rem;
$btn-font-size: 0.875rem;
$btn-font-weight: 500;

/* dropdown */
$dropdown-padding-y: 0;
$dropdown-divider-margin-y: 0;
$dropdown-item-padding-y: 0.5rem;

/* card */
$card-border-radius: 12px;

@import 'bootstrap/scss/bootstrap';

// Then add additional custom code here
:root {
    /* brand colours */
    // neutral
    --black: #{$black};
    --grey: #{$grey};
    --grey-light: #{$grey-light};
    --grey-light2: #{$grey-light2};
    --grey-lighter: #{$grey-lighter};
    // primary
    --primary: #{$primary};
    --primary-dark: #{$primary-dark};
    --primary-light: #{$primary-light};
    --primary-lighter: #{$primary-lighter};

    --primary-light-transparent: #{rgba($primary-light, 0.18)};
    // secondary
    --secondary-red: #{$secondary-red};
    --secondary-green: #{$secondary-green};
    --secondary-purple: #{$secondary-purple};
    --secondary-yellow: #{$secondary-yellow};

    /* custom colours */
    --bg-secondary: #{$bg-secondary};
    --nav-link-item: #{$nav-link-item};
    --white: #{$white};
    --success: #{$success};
    --danger: #{$danger};

    --navbar-height: 60px;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html,
body,
#root {
    height: 100%;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

textarea {
    resize: none;
}

//https://getbootstrap.com/docs/5.0/layout/breakpoints/#min-width
// @include media-breakpoint-up(sm) {
//   body {

//   }
// }

/********
 * Bootstrap Components
 ********
 */

/* loading */
.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
}

.loading-spinner span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    animation: logo 1s ease-in-out infinite;
}

.loading-spinner {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.loading-spinner::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-shadow: 1px 5px 2px $primary;
    
    animation: spinner 1s linear infinite;
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
}

@keyframes logo {
    50% {
        opacity: 0%;
    }
}

/* button */
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:disabled {
    color: var(--white);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-danger:hover,
.btn-outline-danger:focus {
    background-color: transparent;
    opacity: 0.75;
    transition: all 0.2s ease-in-out;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
    color: var(--primary);
}

.btn-outline-danger:hover,
.btn-outline-danger:focus {
    color: var(--danger);
}

/* card */
.card.card-clickable:hover {
    background-color: var(--grey-lighter);
    cursor: pointer;
}
.card-body {
    padding: 1.5rem;
}

/* carousel */
.carousel,
.carousel-inner,
.carousel-item {
    height: 100%;
}

.carousel-control-prev,
.carousel-control-next {
    border-radius: 50%;
    margin: auto 1rem;
    width: 2rem;
    height: 2rem;
    opacity: 1;
    background-color: rgba(black, 0.65);
    box-shadow: 0 1px 2px var(--black);
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
    background-color: rgba($black, 0.65);
}

/* Modal */
@include media-breakpoint-down(md) {
    .modal-dialog {
        padding: 5rem 0;
    }
}
.modal-header {
    background-color: var(--primary-dark);
    text-transform: uppercase;
    color: var(--white);
    padding: 0.8rem;

    button {
        background-color: var(--primary-dark) !important;
    }
}
.modal-content {
    border-width: 0;
}
.modal-title {
    font-size: 1.125rem;
}
.modal-66w {
    width: 96%;
    max-width: none !important;
}
@include media-breakpoint-up(md) {
    .modal-66w {
        width: 66.67%;
        max-width: none !important;
    }
}
.modal-100w {
    padding-left: 0 !important;
    padding-top: 0.5em;
}
.modal-100w::before {
    content: '╳';
    font-size: 1.4rem;
    color: var(--white);
    cursor: pointer;
    margin-left: calc(100% - 2.5rem);
    position: absolute;
}
.modal-dialog-100w {
    width: 100%;
    max-width: none;
    margin: 0;
    padding-top: 2.5rem;
    height: 100%;
}
.modal-100w .modal-header,
.modal-100w .modal-footer {
    padding-right: 2rem; /* To account for scrollbar padding in modal body */
}
.modal-100w .modal-body {
    min-height: 81vh; /* To make the modal full screen */
}
.modal-100w .modal-footer > * {
    margin: auto;
}

/* Toast */
.toast {
    width: 250px;
}

/********
 * Custom utilities
 ********
 */
 .border-gray {
    border-color: var(--bs-gray-600) !important;
 }

 .bg-gray {
    background-color: var(--bs-gray-600) !important;
 }

.bg-primary-dark {
    background-color: var(--primary-dark) !important;
}
.bg-secondary-green {
    background-color: var(--secondary-green) !important;
}

.cursor-pointer {
    cursor: pointer;
}

.ts-corner {
    position: absolute;
    top: 4%;
    left: 3%;
}

.te-corner {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.fw-semibold {
    font-weight: 500;
}

.rounded-4 {
    border-radius: 12px;
}

.text-whitespace {
    white-space: pre-wrap;
}

.primary-link {
    color: $primary;
}

.primary-link:hover {
    color: $primary-dark;
    cursor: pointer;
}

.link-underline {
    text-decoration: none;
}
.link-underline:hover {
    text-decoration: underline;
}
.link-btn {
    border: 0;
    background-color: transparent;
    padding: 0;

    /* anchor tag styles */
    color: #2999cd;
}
.link-btn:hover {
    text-decoration: underline;
}

.disabled-with-bg {
    pointer-events: none;
    background-color: var(--bs-gray-200);
}
.disabled-with-opacity {
    pointer-events: none;
    opacity: 0.75;
}

/********
 * Others
 ********
 */
/* Navbar */
.navbar {
    padding-top: 0;
    padding-bottom: 0;
}
.navbar-wrapper {
    padding: 0;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.03), 0px 4px 12px rgba(0, 0, 0, 0.05);
}

// in case the screen size expands while the dropdown nav is not closed in mobile view
@include media-breakpoint-down(md) {
    .navbar .navbar-collapse.show {
        padding-top: 1rem;
        padding-bottom: 1rem;
        box-shadow: 0 0.125rem 0.25rem rgba(34, 34, 34, 0.08);
    }
    .navbar .navbar-collapse .nav-link {
        margin-bottom: 0.25rem;
    }
}
.logo-name {
    font-family: 'Roboto', 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 48px;
}
.navbar .navbar-brand .nav-link {
    &:hover,
    &:focus {
        color: var(--primary);
    }
}
.navbar .navbar-nav .nav-link {
    color: var(--nav-link-item);
    padding: 0.8rem;

    &.active {
        font-weight: 600;
    }

    &.active,
    &:hover,
    &:focus {
        color: var(--primary);
        background: rgba($primary, 0.08);
        border-radius: 8px;
    }
}

/* GOOGLE MAPS */
.pac-container {
    z-index: 1100;
}

/*web-kit date picker*/
.form-select {
    -webkit-appearance: none;
}
.form-control::-webkit-datetime-edit {
    display: inline;
}

/*skeleton text*/
.skeleton-box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;
    vertical-align: middle;
    border-radius: 4px;
}
.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: -webkit-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
    );
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
}
@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

/*nested modals*/
.modal-layer-1.modal {
    z-index: 1065;
}
.modal-layer-1.modal-backdrop {
    z-index: 1060;
}
.modal-layer-2.modal {
    z-index: 1075;
}
.modal-layer-2.modal-backdrop {
    z-index: 1070;
}
/*fixed scrollable modal*/
// .modal-dialog-scrollable .modal-body {
//     margin-bottom: 70px;
// }
// .modal-dialog-scrollable .modal-footer {
//     background: white;
//     position: absolute;
//     bottom: 0;
//     width: 100%;
//     height: 70px;
//     padding-top: 0;
//     padding-bottom: 0;
// }

/* TABLE Stuff */
table {
    border: 1px solid rgba(211, 211, 211, 0.5);
    border-radius: 8px;
    border-spacing: 0;
    border-collapse: separate;
}
table thead tr th:first-child {
    border-top-left-radius: 8px;
}
table thead tr th:last-child {
    border-top-right-radius: 8px;
}
table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}
table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}
.table > thead {
    background: #f9f9f9;
}
.table > thead:not(:last-child) > tr:last-child > * {
    border-bottom: 1px solid rgba(211, 211, 211, 0.5);
}
.table > tbody > tr {
    // cursor: pointer;
    vertical-align: middle;
}
.table > thead > tr > th.tcol-actions,
.table > tbody > tr > td.tcol-actions {
    width: 5.75rem;
}
.table > thead > tr > th {
    padding: 0.5rem;
}
.table > tbody > tr > td {
    padding: 0.5rem;
}
.table > thead > tr > th:first-child,
.table > tbody > tr > td:first-child {
    padding-left: 0.8rem;
}

/* page header */
.page-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--primary);
    padding: 0.5rem 1rem;
    min-height: 2rem;
}

.page-header.inactive {
    &,
    &::after {
        transition: all 200ms ease;
        background-color: var(--bs-gray-500);
    }
}
button.page-header.inactive:hover {
    &,
    &::after {
        background-color: var(--bs-gray-400);
    }
}

// Change input hint text colour to grey
// ** start **
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--grey) !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--grey) !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--grey) !important;
}
// ** end **

/********
 * Assets page
 ********
 */
/* google map container */
.assets-googleMapContainer {
    height: 40vh;
}

@include media-breakpoint-up(md) {
    .assets-googleMapContainer {
        height: calc(100vh - 11rem);
    }
}

@include media-breakpoint-up(md) {
    .sticky-after-navbar {
        position: sticky;
        top: var(--navbar-height);
        height: calc(100vh - var(--navbar-height));
        overflow: auto;
    }
}
@include media-breakpoint-up(lg) {
    .sticky-after-navbar-lg {
        position: sticky;
        top: var(--navbar-height);
        height: calc(100vh - var(--navbar-height));
        overflow: auto;
    }
}

#assetSideNav > .nav-link {
    color: var(--bs-gray-600);
}
#assetSideNav > .nav-link.active,
#assetSideNav > .nav-link:hover {
    color: var(--primary);
}

/********
 * Asset page
 ********
 */
.assetKPIDropdownMenu .dropdown-item:active {
    color: #1e2125;
    background-color: #e9ecef;
}

@import '~react-phone-number-input/style.css';

.PhoneInputInput {
    padding: 0.375rem 2.25rem 0.375rem 3.5rem;
    width: 100%;
}

.PhoneInputCountry {
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
    z-index: 4; // to show the flag on top when error message appears
}

.input-group ~ .invalid-feedback {
    display: block;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.linkUnderline:hover {
    text-decoration: underline;
    cursor: pointer;
}

a.linkUnderline:not(:hover) {
    text-decoration: none;
}
