/*
 ****************************
 * Assets
 ***************************
 */
/* add asset */
.mapContainer {
    height: 100%;
    min-height: 200px;
}
.stepIcon:hover {
    opacity: 0.6;
    cursor: pointer;
}
.linkUnderline:hover {
    text-decoration: underline;
    cursor: pointer;
}
.createOverlay {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.assetsRadio:hover {
    background-color: var(--bs-gray-200);
}
.assetsRadio.checked {
    background-color: var(--primary-light-transparent);
}

/* search */
.channelsSearch > input {
    padding: 0.3rem 1rem;
}

/* space search */
.searchContainer {
    position: relative;
}

.searchContainer > input {
    /* padding to offset the search button */
    padding-left: 0.85rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: calc(0.5rem + 17px);
    background-color: var(--white);
    border-radius: 12px;
}

.searchContainer button {
    position: absolute !important;
    right: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
}

/* space pic display */
.spacePic {
    height: 20rem;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    background-color: var(--bs-gray-900);

    transition: all 0.1s ease-in-out;
}

.spacePic:hover {
    opacity: 0.95;
    transition: opacity 0.1s ease-in-out;
}

/* space pic edit */
.spacePictureContainer {
    position: relative;
    max-width: 70% !important;
    height: 20rem;
    padding: 0 !important;
}

.spacePictureContainer img {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.overlay {
    position: absolute;
    background-color: rgba(0,0,0,0.3);
    opacity: 0.8;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;

    transition: all .3s ease;
    cursor: pointer;
}

.overlay:hover {
    background-color: rgba(0,0,0,0.7);
    opacity: 1;
}

.spacePictureContainer svg {
    position: absolute;
    color: white;
    font-size: 4rem;
    opacity: 0.9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* list card view */
.userListCard:hover {
    background-color: var(--grey-lighter);
}

.userListCardImageContainer {
    position: relative;
    overflow: hidden;
}

.userListCardImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: all 0.5s;
    transform: scale(1);
}

.userListCard:hover .userListCardImage {
    transform: scale(1.1);
}

.userListCardTitle {
    height: 38px;
}

.userListCardDescription {
    height: 20px;
}

.userListCardIcons {
    height: 40px;
}


/*
 ****************************
 * Asset
 ***************************
 */
.mediaContainer {
    height: 21rem;
}

.assetCode {
    white-space: nowrap;
    position: relative;
    margin: 0 5px 0 10px;
    display: inline-block;
    height: 25px;
    border-radius: 2px 5px 5px 2px;
    padding: 0 20px 0 15px;
    background-color: var(--primary-dark);
    border: 1px solid var(--primary-light);
    color: var(--white);
    line-height: 23px;
    font-size: small;
}
.assetCode:before {
    position: absolute;
    background-color: var(--primary-dark);
    content: '\25CF';
    color: white;
    text-shadow: 0 0 1px #aaa;
    font-size: 12px;
    line-height: 13px;
    text-indent: 6px;
    top: 3px;
    left: -9px;
    width: 18px;
    height: 17.2px;
    transform: rotate(45deg);
    border-left: 1px solid var(--primary-light);
    border-bottom: 1px solid var(--primary-light);
    border-radius: 0 0 0 5px;
}

.secondaryTitle {
    color: var(--grey);
    display: inline-flex;
    vertical-align: middle;
}

.assetGoogleMapModeToggle {
    position: absolute;
    top: 16%;
    right: 4%;
    border-radius: 12px;
}

.assetIconWrapper {
    display: inline-flex;
    align-items: center;
}

.assetIcon {
    margin-right: 0.5rem;
    /* font-size: large; */
}

.col {
    margin-bottom: 1.5rem;
}
.col:nth-child(odd) {
    border-right: 1px solid var(--grey-light);
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1200px) {
    .col {
        border-right: 1px solid var(--grey-light);
    }
    .col:last-child {
        border-right: 0;
    }
}

.colItem {
    padding-bottom: 1.5rem;
}

.colItem:last-child {
    padding-bottom: 0;
}

.featureColItem {
    padding-bottom: 1rem;
}

/* select last 1 child */
.featureColItem:nth-last-child(-n + 1) {
    padding-bottom: 0;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    /* select last 4 child */
    .featureColItem:nth-last-child(-n + 4) {
        padding-bottom: 0;
    }
}

.detailsTable tr td:last-child {
    text-align: end;
    width: 50%;
}

/*
 ****************************
 * Create asset modal
 ***************************
 */
.searchSpinner {
    position: absolute;
    top: 25%;
    right: 2%;
}

/*
 ****************************
 * Calendar page
 ***************************
 */
.datePicker::-webkit-datetime-edit {
    display: inline;
}

/*
 ****************************
 * Lease page
 ***************************
 */
.leaseTable > thead > tr,
.leaseTable > thead > tr > th {
    border-width: 0;
    border-bottom: 1px solid black;
}
.leaseTable > colgroup > col:nth-child(1),
.leaseTable > tbody > tr > td:nth-child(1) {
    width: calc(calc(100% - 5.75rem) * 0.25);
}
.leaseTable > colgroup > col:nth-child(2),
.leaseTable > tbody > tr > td:nth-child(2) {
    width: calc(calc(100% - 5.75rem) * 0.25);
}
.leaseTable > colgroup > col:nth-child(3),
.leaseTable > tbody > tr > td:nth-child(3) {
    width: calc(calc(100% - 5.75rem) * 0.25);
}
.leaseTable > colgroup > col:nth-child(4),
.leaseTable > tbody > tr > td:nth-child(4) {
    width: calc(calc(100% - 5.75rem) * 0.25);
}

/* asset kpi */
.assetKPI {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}
.assetKPITitle {
    position: relative;
    padding: 0.625rem 0;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 600;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.assetKPIData {
    padding: 0.625rem;
}
.assetKPITitleMenu {
    position: absolute;
    top: 15%;
    right: 1%;
}

.disabledLink {
    pointer-events: none;
    opacity: 0.6;
}

/*
 ****************************
 * Conversations page
 ***************************
 */
.conversationsContainer {
    height: calc(100vh - var(--navbar-height) - 1.5rem - 48px - 1.5rem);
}
/* sidebar */
.conversationMemberIconContainer:not(:last-child) {
    margin-right: 0.1rem;
}
.conversationMemberIcon {
    transition: transform 150ms ease;
}
.conversationMemberIcon:hover {
    transform: scale(1.25);
}
.dropdown {
    transition: all 200ms ease-in-out;
}
/* when hover is supported (mouse input) */
@media (hover: hover) {
    /* don't hide dropdown when menu is open */
    .dropdown:not(:global(.show)) {
        opacity: 0;
    }
}
.conversationsSidebarItem:hover .dropdown,
.convoMsgCard:hover .dropdown,
.roleCard:hover .dropdown {
    opacity: 1;
}
/* content */
.convoMsgsContainer {
    background-color: #f1f1f1;
}
.convoMsgCard {
    border-radius: 12px;
}
a.linkUnderline:not(:hover) {
    text-decoration: none;
}
.messageUsername,
.messageUsername:hover {
    font-size: 0.75rem;
    color: darkgray;
}
.convoMsgAttachPlaceholder,
.convoMsgAttachThumbnail {
    height: 30px;
}
.convoMsgAttachPlaceholder {
    width: min-content;
    font-size: 5px;
}
.convoMsgAttachThumbnail {
    object-fit: cover;
    object-position: top;
    border-radius: 4px;
    filter: drop-shadow(0 0 1px var(--bs-gray-500));
}
.convoMsgAttachItem {
    border-radius: 8px;
    background: var(--bs-gray-200);
    padding: 0.5rem;
    border: none;
    text-align: center;
    transition: all 200ms ease-in-out;
}
/* send message container */
.convoMsgInput {
    resize: none;
    overflow: auto;
    line-height: 19px;
    height: auto;
    box-sizing: border-box;
}
.convoMsgAttachPreview {
    width: 32px;
    height: 40px;
    font-size: 8px;
}

.partnerPfp {
    width: 100px;
    height: 100px;
    font-size: 8px;
}

.partnerPfp:hover {
    opacity: 0.8;
}

.partnerPfp img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    box-shadow: 0 0 1px black;
}

.partnerPfp:hover .convoMsgAttachRemoveBtn {
    opacity: 1;
}

.convoMsgAttachPreview:hover {
    opacity: 0.8;
}
.convoMsgAttachPreview:not(:last-child) {
    margin-right: 0.5rem;
}
.convoMsgAttachPreview img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
    box-shadow: 0 0 1px black;
}
.convoMsgAttachRemoveBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    border: none;
    padding: 0.15rem;
    background-color: var(--bg-secondary);
    border-radius: 50%;
    box-shadow: 0 0 1px black;
    margin: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: all 200ms ease-in-out;
}
@media (hover: hover) {
    .convoMsgAttachRemoveBtn {
        opacity: 0;
    }
}
.convoMsgAttachPreview:hover .convoMsgAttachRemoveBtn {
    opacity: 1;
}
.convoMsgAttachRemoveBtn > svg {
    font-size: 14px;
}

/* roles */
.roleCard:hover {
    background-color: var(--grey-lighter);
}
