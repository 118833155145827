.newPostContainer, .postContainer {
    border: none;
    outline: none;
    transition: all 200ms ease;
    border-radius: 8px;
    background-color: var(--white);
}

.newPostContainer > .postInput {
    transition: all 200ms ease;
    box-shadow: 0 0 2px 2px white;
    background-color: var(--white);
}

.newPostContainer > textarea.postInput {
    padding: .65rem;
    line-height: 24px;
}

.newPostContainer > input.postInput {
    transition: all 200ms ease;
    max-height: 45px;
    opacity: 1;
}

.newPostContainer > input.postInput.collapsed {
    max-height: 0;
    padding: 0;
    opacity: 0;
}

.newPostContainer .imagePreview {
    list-style: none;
}
.newPostContainer .imagePreview:last-child {
    flex-grow: 1;
}

.newPostContainer .imagePreview > div {
    background-color: var(--bs-gray-900);
    word-break: break-all;
}
.newPostContainer .imagePreview > img, .newPostContainer .imagePreview > div {
    width: 75px;
    height: 75px;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
    box-shadow: 0 0 1px black;
    cursor: grab;
}

.imagePreview > .removeBtn:hover {
    opacity: 0.8;
}

.imagePreview > .removeBtn {
    position: absolute;
    top: 0.25rem;
    /* can't position using right as the list item stretches for last item (for drop zone) */
    left: calc(75px - 1.25rem);
    border: none;
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--bg-secondary);
    border-radius: 50%;
    box-shadow: 0 0 1px black;
}

.postMsg {
    white-space: pre-wrap;
}

.linkBorder {
    border: 1px solid var(--bs-gray-200);
}

button.linkPreview {
    background-color: var(--bs-gray-300);
}

.linkPreview > img {
    object-fit: cover;
    transition: transform 300ms ease;
}
.linkPreview:hover > img {
    transform: scale(1.05);
}
.linkPreview:hover h6, .linkPreview:focus-within h6 {
    text-decoration: underline;
}

.url {
    color: black;
}

.url:hover {
    color: var(--bs-gray-700);
}

.playBtn {
    z-index: 1;
    background-color: var(--primary-dark);
    transform: translateY(25%);
    transition: opacity 300ms ease;
}
.playBtn > svg {
    font-size: 40px;
    color: var(--white);
}

.linkPreview:hover .playBtn {
    opacity: 0.9;
}

.removeBtn > svg {
    font-size: 1rem;
}

.postHeader > img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.hashtag:hover {
    opacity: 0.7;
}

.hashtag::before {
    content: "#"
}

.personTag {
    color: black;
    font-weight: bold;
    cursor: pointer;
}

.personTag:hover {
    color: var(--bs-gray-700);
}

.newPostContainer .hashtag:hover,
.newPostContainer .hashtag:focus-within,
.newPostContainer .personTag:hover,
.newPostContainer .personTag:focus-within {
    cursor: pointer;
    opacity: 0.7;
}

/* Post */
.postCarousel {
    width: 100%;
    height: 300px;
}
.postCarousel img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* center image if not full */
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.reactionOverlay :global(.popover-arrow) {
    display: none !important;
}
.reactionOverlay .reactionIconBtn:not(:last-child) {
    margin-right: 1rem;
}
.reactionOverlay .reactionIconBtn {
    padding: 0;
    border: none;
    background: transparent;
    display: block;
    transition: transform 150ms ease;
    transform-origin: bottom;
}

.reactionOverlay .reactionIconBtn:disabled {
    opacity: 0.8;
}
.reactionOverlay .reactionIconBtn:hover:not(:disabled) {
    transform: scale(1.25);
}

/* Sidebar */
.sidebarSearch {
    position: relative;
}

.sidebarSearch > input {
    /* padding to offset the search button */
    padding-left: 0.85rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: calc(0.5rem + 17px);
    background-color: var(--white);
    border-radius: 12px;
}

.sidebarSearch > button {
    position: absolute;
    right: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
}

.userSearchItem:hover {
    background: var(--bg-secondary);
    cursor: pointer;
}
.navLink {
    color: var(--bs-gray-600);
    border-left: 3px solid;
    border-color: transparent;
    padding: .5rem 1rem;
}
.navLink.active {
    border-color: var(--primary);
    color: var(--primary);
    font-weight: 500;
}
.navLink.subItem {
    padding: .25rem 1rem;
}
.navLink.subItem > div {
    margin-left: 0;
}

/* Comments  */
.commentModal {
    height: 90%;
}

div.commentCard {
    border-radius: 12px;
    background-color: var(--grey-light2);
}

@media (hover: hover) {
    .commentCard .optionsBtn {
        opacity: 0;
    }
}
.commentCard .optionsBtn {
    transition: opacity 200ms ease;
}
.commentCard:hover .optionsBtn {
    opacity: 1;
}

textarea.commentInput {
	resize: none;
	overflow: auto;
    line-height: 24px;
    height: auto;
    box-sizing: border-box;
}
.linkUnderline {
    text-decoration: none;
}
.linkUnderline:hover {
    text-decoration: underline;
}
a .userAvatar:hover, button .userAvatar:hover {
    box-shadow: 0 0 0 2px var(--primary);
}

/* Profile page */
.editProfileForm label {
    font-size: small;
}

.assetCard {
    transition: transform 300ms ease;
}
.assetCard:hover {
    z-index: 1;
    transform: scale(1.2);
}

button.followBtn {
    text-transform: uppercase;
    font-size: 0.7rem;
    width: 90px;
}
.followBtn.grey {
    border-color: var(--bs-gray-600) !important;
    color: var(--bs-gray-600);
}
.followBtn.grey:hover {
    color: var(--bs-gray-500);
}

/* Users list */
.modalHeaderBtn:hover, .modalHeaderBtn.active {
    background-color: var(--bs-gray-200);
}
.modalHeaderBtn {
    max-width: 100px;
    transition: background-color 200ms ease;
}