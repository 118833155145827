.card:hover {
  background-color: var(--grey-lighter);
}

.cardImageContainer {
  position: relative;
  overflow: hidden;
}

.cardImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
  transition: all 0.5s;
  transform: scale(1);
}

.card:hover .cardImage {
  transform: scale(1.1);
}

.cardTitle {
  height: 38px;
}

.cardDescription {
  height: 56px;
}

.cardIcons {
  height: 40px;
}

/* Upload modal */
.dropZone {
  background-color: var(--bs-gray-300);
}

.dropZone * {
  /* to disable drag-leave events */
  pointer-events: none;
}

.linkUnderline:hover {
  text-decoration: underline;
  cursor: pointer;
}

a.linkUnderline:not(:hover) {
  text-decoration: none;
}

.draggableContainer {
  min-height: 20rem;

}

.placeholder {
  cursor: grab !important;
}