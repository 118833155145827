.classes, .channel {
	--border-radius: 12px;
	border-radius: var(--border-radius);
}

.grid .new, .grid .channel {
	height: calc(100% - 1rem);
}

.classes .image {
	width: 100%;
	object-fit: cover;
}
.classes .image.channelImage {
	object-fit: contain;
}

.grid .classes .image {
	height: 150px;
	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
}

.list .classes .image {
	height: 100px;
	border-radius: 6px;
}

.classes .description {
	font-size: small;
	color: var(--bs-gray-600);
}

.channel .image {
	object-fit: contain;
	width: 100%;
}

.imageContainer {
	background: var(--bs-gray-200)
}
.grid .imageContainer {
	border-top-left-radius: var(--border-radius);
	border-top-right-radius: var(--border-radius);
}

.grid .channel .image {
	height: 100px;
}
.list .channel .image {
	height: 65px;
}

.viewToggle > *:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.viewToggle > *:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

/* Sidebar */
.sidebarSearch {
	position: relative;
}

.sidebarSearch > input {
	/* padding to offset the search button */
	padding-left: 1rem;
	padding-top: .5rem;
	padding-bottom: .5rem;
	padding-right: calc(0.75rem + 17px);
	background-color: var(--white);
	border-radius: 12px;
}

.sidebarSearch > button {
	position: absolute;
	right: 0.5rem;
	top: 50%;
	transform: translateY(-50%);
}

.glowing {
    animation: glow linear alternate infinite 2s;
}
@keyframes glow {
    from { box-shadow: 0 0 5px 5px var(--primary-light); }
    to { box-shadow: 0 0 5px 1px var(--primary-light);; }
}